import React from 'react'
import Loader from "react-loader-spinner"

import './loading.scss'

export default function Loading() {
    return (
        <div className='loader-container'>
            <Loader
                className="loader"
                type="TailSpin"
                color="#324D40"
                height={80}
                width={80}
            />
        </div>
    )
}
