import { all, call, put, takeLatest } from 'redux-saga/effects';
import { actions, urls } from '../utils/constants';
import { apiRequest } from '../utils/functions';

function* setContactMessage({ payload }) {
    const { data: {email, name, phone: tel, textarea: text}, callback } = payload;
    try {
        yield call(apiRequest, {
            url: `${urls.CONTACT_US}`,
            method: 'POST',
            data: {
                email,
                name,
                tel,
                text
            },
        });
        callback()
    } catch(error) {
        yield put({ type: actions.SET_CONTACT_ERROR, payload: error.message || 'unknow' })
    }
}


export default function* probono() {
    yield all([
        takeLatest(actions.SET_CONTACT_REQUEST, setContactMessage),
    ])
}