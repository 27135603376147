export default {
    ROOT: '/',
    LOGIN: '/login',
    SCIS: '/scis',
    DASHBOARD: '/videos',
    LIBRARY: '/videos/library',
    BONUS: '/videos/bonus',
    CATEGORY: '/videos/category_id/',
    PLAYER: '/videos/video_id/',
    ACCOUNT: '/videos/account',
    PROBONO: '/videos/probono',
    CONTACT_US: '/public/site-scis/contact'
}