import moment from 'moment'
import RemoveAccents from 'remove-accents'
import Payment from 'payment';

import { urls, settingsAuth } from './constants'
import routes from './routers.constants'

import Axios from 'axios'
import CredentialHelper from './credential.helper'

const axios = Axios
axios.defaults.timeout = 15000
axios.interceptors.response.use(async (config) => {
    return config;
}, async (error) => {
    if (error.response.status === 401) {
        CredentialHelper.clear()
        window.location.href = urls.LOGOUT
    }
    return Promise.reject(error);
});
/**
 * @param {string} params
 * @return {string}
 */
export const overwriteAuthorizeUrl = (params) => {

    const paths = params.replace(routes.DASHBOARD, '')

    let state = ''

    if (paths !== '/' && paths !== '') {
        state = `&state=${paths}`
    }

    const newUrl = `${urls.AUTHORIZE}${state}`

    return encodeURI(newUrl)
}

/**
 * @param {string} code
 * @returns {object}
 */
export const getToken = async (code) => {
    try {

        const request = {
            code,
            grant_type: 'authorization_code',
            client_id: settingsAuth.client_id,
            machine: window.navigator.userAgent,
            scope: 'admin',
            redirect_uri: settingsAuth.redirect_uri_login,
        }
        const data = await apiRequest({
            data: request,
            headers: {
                'Content-Type': 'application/json',
            },
            url: encodeURI(urls.TOKEN),
            method: 'POST'
        })

        return data

    } catch (error) {
        //TODO send Error
        throw error
    }
}


/**
 * @param {string} text 
 * @returns { string | null } Text
 */
export const firstCapitalLetter = (text) => {
    if (text && text.length > 0) {
        const lower = text.toLowerCase()

        return lower.replace(/^./, lower[0].toUpperCase())
    }

    return text
}

export function normalizeName(name = ' ') {
    let [firstName, lastName, ocultName] = (name || ' ').split(' ');
    lastName = lastName || ocultName;
    return `${firstName && firstName.replace(/^./, firstName[0] && firstName[0].toUpperCase())} ${lastName && lastName.replace(/^./, lastName[0] && lastName[0].toUpperCase())}`;
}

/**
 * @param {object} request 
 * @returns {any}
 */
export const apiRequest = async (request) => {
    const headers = {
        ...request.headers,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CredentialHelper.accessToken()}`
    }
    request = { ...request, headers }
    const { data } = await axios({ ...request, url: encodeURI(request.url) })
    return data
}

export const formatDuration = time => moment.utc(time * 1000).format('HH:mm:ss')

/**
 * @param {string} text 
 * @param {Array} array
 * @param {string} param
 */
export function searchFunc(text, array, param = 'title') {
    if (array.length > 0) {
        const filtered = array.filter(item => {
            const string = RemoveAccents.remove(text.toLocaleLowerCase())
            const title = RemoveAccents.remove(item[param].toLocaleLowerCase())

            return title.indexOf(string) !== - 1
        })
        return filtered
    } else {
        return array
    }
}

export function clearNumber(value = '') {
    return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 15)}`;
            break;
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === 'amex' ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
}

export function formatStringExpirationDate(value) {
    const formattedString = value.split('/')[0] + '/20' + value.split('/')[1];

    return formattedString;
}

export function formatFormData(data) {
    return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

export function formatBrand(brand) {
    const hashTable = {
        mastercard: 'Master',
        visa: 'Visa',
        amex: 'Amex',
        elo: 'Elo',
        aura: 'Aura',
        jcb: 'JCB',
        dinersclub: 'Diners',
        discover: 'Discover',
        hipercard: 'Hipercard',
        hiper: 'Hiper'
    };

    return hashTable[brand];
}

export function sortArray(field, array) {
    return array.sort(function (prev, next) {
        let textA = prev[field].toUpperCase()
        let textB = next[field].toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
    })
}