import { all } from 'redux-saga/effects'

import login from './login'
import videos from './videos'
import probono from './probono'
import contact from './contact'

function *rootSaga() {
    return yield all([
        login(),
        videos(),
        probono(),
        contact(),
    ])
}

export default rootSaga