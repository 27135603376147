const {
    REACT_APP_PORTAL_COACHES_CLIENT_ID,
    REACT_APP_LOGIN_BASE_URL,
    REACT_APP_API_V1_BASE_URL,
    REACT_APP_API_V2_BASE_URL,
    REACT_APP_OSEUCOACH_BASE_URL,
    REACT_APP_STAGE: stage,
} = process.env

const client_id = REACT_APP_PORTAL_COACHES_CLIENT_ID

export const settingsAuth = {
    client_id,
    redirect_uri_login: `${window.location.origin}/videos`,
    redirect_uri_logout: `${window.location.origin}/`
}

export const actions = {
    //APP
    SET_ERROR: 'SET_ERROR',
    //LOGIN
    SET_CODE_OAUTH: 'SET_CODE_OAUTH',
    ASYNC_DO_LOGIN: 'ASYNC_DO_LOGIN',
    DO_LOGIN_SUCCESS: 'DO_LOGIN_SUCCESS',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    FORCE_LOGOUT: 'FORCE_LOGOUT',
    LOGOUT: 'LOGOUT',
    //USER
    ASYNC_FETCH_USER_PROFILE: 'ASYNC_FETCH_USER_PROFILE',
    FETCH_USER_PROFILE_SUCCESS: 'FETCH_USER_PROFILE_SUCCESS',
    //VIDEO
    ASYNC_FETCH_VIDEOS: 'ASYNC_FETCH_VIDEOS',
    SET_VIDEOS: 'SET_VIDEOS',
    FETCH_VIDEOS_ERROR: 'FETCH_VIDEOS_ERROR',
    ASYNC_FETCH_VIDEO: 'ASYNC_FETCH_VIDEO',
    SET_VIDEO: 'SET_VIDEO',
    FETCH_VIDEO_ERROR: 'FETCH_VIDEO_ERROR',
    ASYNC_SET_LAST_VIDEO: 'ASYNC_SET_LAST_VIDEO',
    SET_LAST_ACCESS: 'SET_LAST_ACCESS',
    ASYNC_GET_LAST_VIDEO: 'ASYNC_GET_LAST_VIDEO',
    SET_VIDEOS_TREE: 'SET_VIDEOS_TREE',
    SET_CATEGORIES_TREE: 'SET_CATEGORIES_TREE',
    //PROBONOS
    ASYNC_FETCH_COACHES_PROBONO: 'ASYNC_FETCH_COACHES_PROBONO',
    ASYNC_SET_COACHES_PROBONO: 'ASYNC_SET_COACHES_PROBONO',
    ASYNC_ERROR_COACHES_PROBONO: 'ASYNC_ERROR_COACHES_PROBONO',
    ASYNC_FETCH_COACH_PROBONO: 'ASYNC_FETCH_COACH_PROBONO',
    ASYNC_SET_COACH_PROBONO: 'ASYNC_SET_COACH_PROBONO',
    ASYNC_ERROR_COACH_PROBONO: 'ASYNC_ERROR_COACH_PROBONO',
    ASYNC_ASK_COACH_PROBONO_SESSION: 'ASYNC_ASK_COACH_PROBONO_SESSION',
    SET_PROBONO_SUCCESS: 'SET_PROBONO_SUCCESS',
    ASYNC_FETCH_EXPERTISES_PROBONO: 'ASYNC_FETCH_EXPERTISES_PROBONO',
    ASYNC_SET_EXPERTISES_PROBONO: 'ASYNC_SET_EXPERTISES_PROBONO',
    ASYNC_FETCH_COACH_PROBONO_PAGINATION: 'ASYNC_FETCH_COACH_PROBONO_PAGINATION',
    //CONTACT
    SET_CONTACT_REQUEST: 'SET_CONTACT_REQUEST',
    SET_CONTACT_ERROR: 'SET_CONTACT_ERROR',

}

const origin = window.location.origin

export const urls = {
    AUTHORIZE: `${REACT_APP_LOGIN_BASE_URL}/v2/oauth2/authorize?client_id=${client_id}&redirect_uri=${origin}/videos&response_type=code&scope=admin`,
    LOGOUT: `${REACT_APP_LOGIN_BASE_URL}/v2/oauth2/logout?client_id=${client_id}&redirect_uri=${settingsAuth.redirect_uri_logout}`,
    TOKEN: `${REACT_APP_LOGIN_BASE_URL}/v2/oauth2/token`,
    PROFILE: `${REACT_APP_API_V1_BASE_URL}/v1/${stage}/profiles/me`,
    VIDEOS: `${REACT_APP_API_V2_BASE_URL}/v2/site-scis/videos`,
    VIDEO: `${REACT_APP_API_V2_BASE_URL}/v2/site-scis/play`,
    STATISTICS: `${REACT_APP_API_V2_BASE_URL}/v2/admin/public/dashboard?type`,
    PROBONO: `${REACT_APP_API_V2_BASE_URL}/v2/pro-bono/coaches`,
    O_SEU_COACH: REACT_APP_OSEUCOACH_BASE_URL,
    EXPERTISE: `${REACT_APP_API_V1_BASE_URL}/v1/${stage}/types/expertises`,
    DOWNLOAD_MAC: `${REACT_APP_API_V2_BASE_URL}/v2/updates/install?platform=mac`,
    DOWNLOAD_WINDOWS: `${REACT_APP_API_V2_BASE_URL}/v2/updates/install?platform=windows`,
    CONTACT_US: `${REACT_APP_API_V2_BASE_URL}/v2/site-scis/public/contact`,
}

export const getCoacheeStats = type => `${urls.STATISTICS}=${type}`