import { actions } from "../utils/constants"

const INITIAL_STATE = {
    isError: false,
    errorMessage: ''
}

const contactUs = (state = INITIAL_STATE, action) => {
    const { payload, type } = action

    switch(type) { 

        case actions.SET_CONTACT_ERROR:
            return { ...state, isError: !state.isError, errorMessage: payload}

        default:
            return state
    }
}

export default contactUs
