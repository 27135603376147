import { all, call, put ,takeLatest, delay } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { actions, urls } from '../utils/constants'
import { getToken, overwriteAuthorizeUrl, apiRequest } from '../utils/functions'
import CredentialHelper from '../utils/credential.helper'
import routers from '../utils/routers.constants'
import history  from '../utils/history'

function *doLogin({ payload }) {
    try {

        const { code, params } = payload

        yield put({ type: actions.SET_CODE_OAUTH, payload: code })

        const { access_token, refresh_token } = yield call(getToken, code)
    
        yield CredentialHelper.update(access_token, refresh_token)

        let route = routers.DASHBOARD
        if(params !== null && params !== routers.LOGIN) {
            route += params
        }
        
        yield put({ type: actions.DO_LOGIN_SUCCESS })

        yield call(history.push, route)

    } catch(error) {
        yield call(setError, error)
    }
}


function *fetchUser() {
    try {

        const request = {
            url: urls.PROFILE,
            method: 'get'
        }

        const data = yield call(apiRequest, request)
        yield put({ type: actions.FETCH_USER_PROFILE_SUCCESS, payload: data })

    } catch(error) {
        yield call(setError, error)
    }
}

function *forceLogout() {
    toast.error('Acesso negado !', {
        position: toast.POSITION.TOP_RIGHT
    })

    yield delay(1000)

    yield logout()
}

function *logout() {
    yield CredentialHelper.clear()
    window.location.href = urls.LOGOUT
}

function *setError(error) {
    if(error && error.response) {
        if(error && (error.message.includes('timeout') || error.message.includes('Network'))) {
            let message = 'Erro ao se conectar ao servidor ! Atualize e tente novamente !'

            return toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    } else {
        if(error && error.message && error.message === 'ACCESS_DENIED') {
            yield put({ type: actions.FORCE_LOGOUT })
        }

        if(error && error.message && error.message === 'TOKEN_EXPIRED') {
            yield put({ type: actions.TOKEN_EXPIRED })
        }
    }
}

function *reLogin() {
    toast.error('Sessão expirada !', {
        position: toast.POSITION.TOP_RIGHT
    })

    yield delay(3000)

    const params = window.location.pathname

    const url = overwriteAuthorizeUrl(params)

    CredentialHelper.clear()
    
    window.location.replace(url)
}

export default function *login() {
    yield all([
        takeLatest(actions.ASYNC_DO_LOGIN, doLogin),
        takeLatest(actions.ASYNC_FETCH_USER_PROFILE, fetchUser),
        takeLatest(actions.TOKEN_EXPIRED, reLogin),
        takeLatest(actions.FORCE_LOGOUT, forceLogout),
        takeLatest(actions.LOGOUT, logout)
    ])
}