import { all, call, put ,takeLatest } from 'redux-saga/effects'
import { actions, urls } from '../utils/constants'
import { apiRequest } from '../utils/functions'
import { toast } from 'react-toastify'

function *fetchCoaches({ payload }) {
    try {

        const { queryParams } = payload

        const data = yield call(apiRequest, {
            url: `${urls.PROBONO}?q=${JSON.stringify(queryParams)}`,
            method: 'GET'
        })

        const { coaches, total } =  data
        const newCoaches = coaches.map(e=>({...e, city: e.city && e.city.toUpperCase().includes('CIDADE') ? undefined : e.city}));
        yield put({ type: actions.ASYNC_SET_COACHES_PROBONO, payload: { coaches: newCoaches, total } })

    } catch(error) {
        yield call(setError, error)
    }
}

function *fetchCoachesPagination({ payload }) {
    try {

        const { queryParams } = payload

        const data = yield call(apiRequest, {
            url: `${urls.PROBONO}?q=${JSON.stringify(queryParams)}`,
            method: 'GET'
        })

        const { coaches, total } =  data

        const newCoaches = coaches.map(e=>({...e, city: e.city && e.city.toUpperCase().includes('CIDADE') ? undefined : e.city}));

        yield put({ type: actions.ASYNC_SET_COACHES_PROBONO, payload: { coaches: newCoaches, total } })

    } catch(error) {
        yield call(setError, error)
    }
}

function *fetchCoach({ payload }) {
    try {

        const { queryParams } = payload

        yield put({ type: actions.ASYNC_SET_COACHES_PROBONO, payload: queryParams })

    } catch(error) {
        yield call(setError, error)
    }
}

function *doSession({ payload }) {
    try {
        const { coach } = payload

        yield call(apiRequest, {
            url: `${urls.PROBONO}/${coach.id}/request`,
            method: 'POST'
        })

        yield put({ type: actions.SET_PROBONO_SUCCESS, payload: coach })

    } catch(error) {
        yield call(setError, error)
    }
}

function *fetchExpertises() {
    try {

        const data = yield call(apiRequest, {
            url: urls.EXPERTISE,
            method: 'GET',
        })

        data.unshift({
            name: 'Todos',
        })

        yield put({ type: actions.ASYNC_SET_EXPERTISES_PROBONO, payload: data })

    } catch(error) {
        yield call(setError, error)
    }
}

function *setError(error) {
    yield put({ type: actions.ASYNC_ERROR_COACHES_PROBONO })
    if(error && error.response) {
        toast.error(error.response.data.error_description, {
            position: toast.POSITION.TOP_RIGHT
        })

        if(error.response.status === 401) {
            yield put({ type: actions.TOKEN_EXPIRED })
        }
    } else {
        toast.error('Erro ao solicitar Probono', {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

export default function *probono() {
    yield all([
        takeLatest(actions.ASYNC_FETCH_COACHES_PROBONO, fetchCoaches),
        takeLatest(actions.ASYNC_FETCH_COACH_PROBONO_PAGINATION, fetchCoachesPagination),
        takeLatest(actions.ASYNC_FETCH_COACH_PROBONO, fetchCoach),
        takeLatest(actions.ASYNC_ASK_COACH_PROBONO_SESSION, doSession),
        takeLatest(actions.ASYNC_FETCH_EXPERTISES_PROBONO, fetchExpertises),
    ])
}