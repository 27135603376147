import { actions } from '../utils/constants'

const INITIAL_STATE = {
    userInfo: null,
    isLoading: false,
    code: null,
}

const app = (state = INITIAL_STATE, action) => {

    const { type, payload } = action

    switch(type) {
        case actions.ASYNC_DO_LOGIN:
            return { ...state, isLoading: true }

        case actions.SET_CODE_OAUTH:
            return { ...state, code: payload }    

        case actions.DO_LOGIN_SUCCESS:
            return { ...state, isLoading: false }
            
        case actions.SET_ERROR:
            return { ...state, isLoading: false }

        case actions.FETCH_USER_PROFILE_SUCCESS:
            return { ...state, isLoading: false, userInfo: payload  }    
        
        default:
            return state
    }
}

export default app