const REFRESH_TOKEN = 'refresh_token';
const ACCESS_TOKEN = 'access_token';

class CredentialHelper {

    /**
     * @param {string} accessToken 
     * @param {string} refreshToken 
     * @returns {void}
     */
    update(accessToken, refreshToken) {
        localStorage.setItem(ACCESS_TOKEN, accessToken)
        localStorage.setItem(REFRESH_TOKEN, refreshToken)
    }

    /**
     * @returns {void}
     */
    clear() {
        localStorage.removeItem(ACCESS_TOKEN)
        localStorage.removeItem(REFRESH_TOKEN)
    }

    /**
     * @returns {string} accessToken
     */
    accessToken() {
        return localStorage.getItem(ACCESS_TOKEN)
    }

    /**
     * @returns {string} refreshToken
     */
    refreshToken() {
        return localStorage.getItem(REFRESH_TOKEN)
    }

    /**
     * @returns {boolean}
     */
    isAuthenticated() {
        return Boolean(this.accessToken())
    }
}

export default new CredentialHelper()