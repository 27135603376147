import { actions } from "../utils/constants"

const INITIAL_STATE = {
    isLoading: false,
    selectedVideo: null,
    lastVideo: null,
    videos: [],
    videosTree: [],
    categoriesTree: [],
}

const videos = (state = INITIAL_STATE, action) => {
    const { payload, type } = action

    switch(type) {
        
        case actions.ASYNC_FETCH_VIDEOS:
            return { ...state, isLoading: true }

        case actions.SET_VIDEOS:
            return { ...state, isLoading: false, videos: payload }
            
        case actions.FETCH_VIDEOS_ERROR:
            return { ...state, isLoading: false }
            
        case actions.SET_VIDEO:
            return { ...state, isLoading: false, selectedVideo: payload }
            
        case actions.ASYNC_FETCH_VIDEO:
            return { ...state, isLoading: true }
        
        case actions.FETCH_VIDEO_ERROR:
            return { ...state, isLoading: false }    

        case actions.ASYNC_GET_LAST_VIDEO:
            return { ...state, isLoading: true }
                
        case actions.SET_LAST_ACCESS:
            return { ...state, lastVideo: payload, isLoading: false }    
        
        case actions.SET_VIDEOS_TREE:
            return { ...state, videosTree: payload }    
        
        case actions.SET_CATEGORIES_TREE:
            return { ...state, categoriesTree: payload}    
        
        default:
            return state
    }
}

export default videos
