import { all, call, put, takeLatest, delay } from 'redux-saga/effects'

import { actions, urls } from '../utils/constants'
import { apiRequest } from '../utils/functions'
import { toast } from 'react-toastify'
import history from '../utils/history'

function *fetchVideos() {
    try {

        const data = yield call(apiRequest, {
            url: urls.VIDEOS,
            method: 'GET'
        })

        const order = yield data.videos.sort((prev, next) => {
            if(prev.title < next.title) { return -1 }
            if(prev.title > next.title) { return 1 }
            return 0
        })
        
        yield all([
            put({ type: actions.SET_VIDEOS, payload: order }),
        ])

    } catch(error) {
        
        yield put({ type: actions.FETCH_VIDEOS_ERROR })

        yield call(setError, error)
    }
}

function *fetchVideo({ payload }) {
    try {
        
        const { videoId } = payload

        if(videoId) {
            const request = {
                url: `${urls.VIDEO}/${videoId}`,
                method: 'get'
            }

            const data = yield call(apiRequest, request)

            const url = data[videoId]

            yield put({ type: actions.SET_VIDEO, payload: url })
                    
        }

    } catch(error) {
        yield put({ type: actions.FETCH_VIDEO_ERROR })

        yield call(setError, error)
    }

}


function *setLastAccess({ payload : video }) {
    try {

        yield call(apiRequest, {
            url: `${urls.SET_VIDEO_LAST_ACCESS}/${video.id}`,
            method: 'POST'
        })

        yield put({ type: actions.SET_LAST_ACCESS, payload: video })

    } catch(error) {

        yield put({ type: actions.SET_LAST_ACCESS, payload: video })

    }
}

function *getLastVideo() {
    try {

        const data = yield call(apiRequest, {
            url: `${urls.GET_VIDEO_LAST_ACCESS}`,
            method: 'GET'
        })
        
        const thumbnail = data.thumbnail.replace('600', '451').replace('480', '264')
        
        yield put({ type: actions.SET_LAST_ACCESS, payload: {
            ...data,
            thumbnail,
        }})

    } catch(error) {
        yield all ([ 
            put({ type: actions.FETCH_VIDEO_ERROR }),
            call(setError, error)
        ])
    
    }
}


function *setError(error) {
    let message = ''
    
    if(error && error.response) {
       
        if(error.response.status === 403 || error.response.status === 401) {
            message = error.response.data.message

            yield toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            })
            
            yield delay(3000)
            
            return yield put({ type: actions.FORCE_LOGOUT })
        }
        
        if(error.response.status === 404) {
            yield call(history.push, 'videos/404')
        }

        toast.error(error.response.data.error_description, {
            position: toast.POSITION.TOP_RIGHT
        })        

    } else {
        if(error && (error.message.includes('timeout') || error.message.includes('Network'))) {
            message = 'Erro ao se conectar ao servidor ! Atualize e tente novamente !'

            return toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }

        if(error && error.message && error.message === 'ACCESS_DENIED') {
            yield put({ type: actions.FORCE_LOGOUT })
        }

        if(error && error.message && error.message === 'TOKEN_EXPIRED') {
            yield put({ type: actions.TOKEN_EXPIRED })
        }

    }

}

function *videos() {
    yield all([
        takeLatest(actions.ASYNC_FETCH_VIDEOS, fetchVideos),
        takeLatest(actions.ASYNC_FETCH_VIDEO, fetchVideo),
        takeLatest(actions.ASYNC_SET_LAST_VIDEO, setLastAccess),
        takeLatest(actions.ASYNC_GET_LAST_VIDEO, getLastVideo),
    ])
}

export default videos