import i18n from "i18next";
import pt from "./pt.json";
import en from "./en.json";
import es from './es.json';

import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
    resources: {
        'pt': {
            translations: {...pt},
        },
        'en': {
            translations: {...en}
        },
        'es': {
            translations: {...es}
        }
    },
    fallbackLng: "pt",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false,
    react: {
        wait: true
    }
});

i18n.changeLanguage(navigator.language || navigator.userLanguage);

export default i18n;