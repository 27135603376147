import React, { Component } from 'react';
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import './config/reactotron.config'

import history from './utils/history'
import './App.scss';
import Routes from './routes';

import store from './config/store.config'

class App extends Component {
  
  render() {
    
    return (
      <Provider store={store}>
        <Router history={history}>
            <Routes />
        </Router>
        <ToastContainer autoClose={5000} />
      </Provider>
    )
  }
}

export default App;
