import { combineReducers } from 'redux';
import app from './app'
import videos from './videos'
import probono from './probono'
import contact from './contact'

const rootReducer = combineReducers({
    app,
    videos,
    probono,
    contact
});

export default rootReducer;
