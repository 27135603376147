import { actions } from "../utils/constants"

const INITIAL_STATE = {
    isLoading: false,
    coaches: null,
    selected_coach: {},
    total: 0,
    probonos: [],
    expertises: [],
}

const probono = (state = INITIAL_STATE, action) => {
    const { payload, type } = action

    switch(type) {
        
        case actions.ASYNC_FETCH_COACHES_PROBONO:
            return { ...state, isLoading: true }

        case actions.ASYNC_SET_COACHES_PROBONO:
            return { ...state, isLoading: false, coaches: payload.coaches, total: payload.total }
            
        case actions.ASYNC_ERROR_COACHES_PROBONO:
            return { ...state, isLoading: false }
        
        case actions.ASYNC_FETCH_COACH_PROBONO:
            return { ...state, isLoading: true }

        case actions.ASYNC_SET_COACH_PROBONO:
            return { ...state, isLoading: false, selected_coach: payload }    

        case actions.ASYNC_ERROR_COACH_PROBONO:
            return { ...state, isLoading: false }        
        
        case actions.ASYNC_ASK_COACH_PROBONO_SESSION:
            return { ...state, isLoading: true }        
        
        case actions.SET_PROBONO_SUCCESS:
            const probonoIndex = state.probonos.findIndex(id => id === payload.id)
            const newProbonos = probonoIndex !== -1 ? state.probonos : [...state.probonos, payload.id]

            return { ...state, isLoading: false, probonos: newProbonos, selected_coach: payload }

        case actions.ASYNC_FETCH_EXPERTISES_PROBONO:
            return { ...state, isLoading: true }
                
        case actions.ASYNC_SET_EXPERTISES_PROBONO:
            return { ...state, isLoading: false, expertises: payload }    
        
        default:
            return state
    }
}

export default probono
