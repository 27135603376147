import React, { lazy, Suspense } from 'react'
import { 
    Route,
    Switch,
} from 'react-router-dom'

import routes from '../utils/routers.constants'
import Loading from '../components/loading'

const Main = lazy(() => import('../containers/main'))
const Login = lazy(() => import('../containers/login'))
const Home = lazy(() => import('../screens/home/home'))
const PrivateRoute = lazy(() => import('./privateRoute'))
const VideosHome = lazy(() => import('../screens/videosHome/videosHome'))
const Player = lazy(() => import('../screens/player/player'))
const Page404 = lazy(() => import('../screens/page404/page404'))
const Probono = lazy(() => import('../screens/probono'))
const ScisPage = lazy(() => import('../screens/scisPage/ScisPage'))
const Home404 = lazy(() => import('../screens/home/home404/home404'))

function Routes() {
    return(
        <Suspense fallback={<Loading />}>
            <Switch> 
                <Route path='/' exact component={() =>  <Home />} />
                <Route exact path={routes.LOGIN} component={Login} />

                <PrivateRoute path={`${routes.DASHBOARD}`} exact component={() => <Main Component={VideosHome} />} />
                <PrivateRoute path={`${routes.SCIS}`} exact component={() => <Main Component={ScisPage} /> }/>
                <PrivateRoute path={`${routes.PLAYER}:video_id`} exact component={() => <Main Component={Player} />} />
                <PrivateRoute path={`${routes.PROBONO}`} exact component={() => <Main Component={Probono} />} />
                <PrivateRoute path={'*'} component={() => <Main Component={Page404} />} />
                <PrivateRoute path={`${routes.DASHBOARD}/*`} component={() => <Main Component={Page404} />} />

                <Route path={'*'} component={() => <Home404 />} />
            </Switch>
        </Suspense>
    )
}

export default Routes